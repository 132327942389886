import { Box, Typography } from '@mui/material';
import EmbedFile from 'components/custom/embedFile';
import dayjs from 'dayjs';
import useModal from 'hooks/useModal';
import DeleteIcon from 'assets/images/icons/navigation/Delete';
import { useState } from 'react';
import { Upload } from 'types/dto/partners.dto';
import { AskConfirmation } from 'components/custom/AskConfirmation';
import { useEndpoint } from 'hooks/useEndpoint';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { queryClient } from 'App';

interface Props {
  taskID: number;
  uploads: Partial<File & Upload>[];
}
export const UploadsList = ({ taskID, uploads }: Props) => {
  const { openModal } = useModal();
  const [uploadCodeToDelete, setUploadCodeToDelete] = useState<string>();

  const deleteUpload = useEndpoint<undefined, 'delete'>({
    method: 'delete',
    endpoint: `/tasks/${taskID}/uploads/${uploadCodeToDelete}`,
    mutationKey: `delete-upload-in-${taskID}`,
    options: {
      onSuccess: () => {
        setUploadCodeToDelete(undefined);
        queryClient.refetchQueries(`get-task-${taskID}`);
        dispatch(
          openSnackbar({
            message: 'File eliminato con successo.',
            open: true,
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `delete-upload-${uploadCodeToDelete}`
          })
        );
        setUploadCodeToDelete(undefined);
      }
    }
  });

  return (
    <Box sx={{ mt: 2, pr: 2 }}>
      {uploads.length ? (
        uploads.map((upload: Partial<File & Upload>, i: number) => (
          <Box key={`upload-${i}`} className="flex ali-center just-btwn">
            <Box
              width={280}
              className="clickable"
              onClick={() =>
                openModal({
                  content: <EmbedFile fileCode={upload?.uploadCode ?? ''} type={upload?.mimetype ?? ''} />
                })
              }
            >
              <Typography flexGrow={1} className="truncate" variant="body2">
                {upload.name}
              </Typography>
            </Box>
            <Typography variant="body2">{dayjs(upload.at).format('DD/MM/YYYY HH:mm')}</Typography>
            <Box
              py={1}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexGrow: 1,
                '&.MuiBox-root': { opacity: 0.2 },
                '&.MuiBox-root:hover': { opacity: 1 }
              }}
            >
              <Box
                onClick={() => {
                  setUploadCodeToDelete(upload.uploadCode);
                  openModal({
                    title: 'Conferma eliminazione',
                    content: (
                      <AskConfirmation
                        confirmationText={`Sei sicuro di voler eliminare il file "${upload.name}"?`}
                        onConfirmCallback={() => deleteUpload.mutate(undefined)}
                        onDismissCallback={() => setUploadCodeToDelete(undefined)}
                      />
                    )
                  });
                }}
              >
                <DeleteIcon width={16} />
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography>Non sono presenti documenti</Typography>
      )}
    </Box>
  );
};
