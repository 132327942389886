import { Avatar, Box, Typography } from '@mui/material';
import { queryClient } from 'App';
import dayjs from 'dayjs';
import { useEndpoint } from 'hooks/useEndpoint';
import { NotificationType } from 'types/dto/notification.dto';
import { notificationAction, notificationObject } from 'utils/discriminators';
import { avatarInitials, shortname } from 'utils/formattation';

interface Props {
  notification: NotificationType;
}
export const SingleNotification = ({ notification }: Props) => {
  const readSingleNotification = useEndpoint<{ ids: number[] }, 'put'>({
    method: 'put',
    endpoint: '/notifications/readings',
    mutationKey: 'notifications-readings',
    options: {
      onSuccess: () => {
        queryClient.refetchQueries('get-all-notifications');
      }
    }
  });

  return (
    <Box className="single-notification clickable" onClick={() => readSingleNotification.mutate({ ids: [notification.id] })}>
      <Box className="flex ali-center" width="80%">
        <Avatar sx={{ margin: 0.8 }}>{avatarInitials(`${notification.user.firstname} ${notification.user.lastname}`)}</Avatar>
        <Box sx={{ width: 430, ml: 1 }}>
          <Box display="flex" width="100%">
            <Box width="62%" display="flex">
              <Typography variant="body2">
                <Typography variant="body2" component="span" sx={{ mr: 0.5, fontWeight: 700 }}>
                  {shortname(notification.user.firstname, notification.user.lastname)}
                </Typography>

                <Typography variant="body2" component="span" sx={{ mr: 0.5 }}>
                  ha {notificationAction(notification.operation)} {notificationObject(notification.resource)}{' '}
                  <Typography variant="body2" component="span" sx={{ fontWeight: 700 }}>
                    {notification.resource === 'order' ? notification.subject : notification.subjectName}
                  </Typography>
                </Typography>
              </Typography>
            </Box>
          </Box>
          {notification?.description && (
            <Typography variant="caption" className="notificationDescription">
              {notification.description.it}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="flex ali-center just-center" width="20%">
        <Box className="flex just-start" sx={{ width: '25%', mr: 0.6 }}>
          {notification.readAt === null && <Box className="read-circle" />}
        </Box>
        <Typography variant="caption" sx={{ mr: 2 }}>
          {dayjs(notification.at).format('DD/MM/YYYY HH:mm')}
        </Typography>
      </Box>
    </Box>
  );
};
