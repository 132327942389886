import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Collapse,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  IconButton,
  useMediaQuery
} from '@mui/material';

// project import
import NavItem from './NavItem';

// assets
import { BorderOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

// types
import { NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { activeItem } from 'store/reducers/menu';
import Transitions from 'components/custom/Transitions';

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    borderBottom: `1px solid ${theme.palette.grey.A800}`
  }
}));

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
  menu: NavItemType;
  level: number;
}

// componente ereditato
// definisce il componente per visualizzare la voce di gruppo
// modificato

const NavCollapse = ({ menu, level }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const menuState = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menuState;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);
  const isSelected = menuState.openItem.findIndex((id) => id === menu.id) > -1;
  const [anchorEl, setAnchorEl] = useState<VirtualElement | (() => VirtualElement) | null | undefined>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
    setAnchorEl(null);
    if (drawerOpen) {
      setOpen(!open);
      setSelected(!selected ? menu.id : null);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // controllo per determinare la voce di menù attiva
  // sicuramente si può fare senza useEffect

  useEffect(() => {
    const childrens = menu.children ? menu.children : [];
    childrens.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(menu.id);
        return;
      }
    });
    if (pathname.includes('buildings')) {
      dispatch(activeItem({ openItem: ['buildings'] }));
    }
    if (pathname.includes('orders')) {
      dispatch(activeItem({ openItem: ['orders'] }));
      return;
    }
    if (pathname !== '/users' && pathname.includes('fast-registration-users')) {
      dispatch(activeItem({ openItem: ['fast-registration-users'] }));
      return;
    }
    if (pathname !== '/users' && pathname.includes('users')) {
      dispatch(activeItem({ openItem: ['users'] }));
      return;
    }
    if (pathname !== '/collaborators' && pathname !== '/collaborators/new' && pathname.includes('collaborators')) {
      dispatch(activeItem({ openItem: ['collaborators'] }));
      return;
    }
    if (pathname.includes('autopilot')) {
      dispatch(activeItem({ openItem: ['autopilot'] }));
      return;
    }
    if (menu.url && pathname === menu.url) {
      dispatch(activeItem({ openItem: [menu.id] }));
      return;
    }
  }, [pathname, menu]);

  const openMini = Boolean(anchorEl);

  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  const borderIcon = level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false;
  const Icon = menu.icon!;
  const menuIcon = menu.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : borderIcon;
  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';

  return (
    <>
      <ListItemButton
        disableRipple
        selected={isSelected}
        {...(!drawerOpen && !matchDownSM && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
        onClick={handleClick}
        sx={{
          pl: drawerOpen && !matchDownSM ? 2.8 : 1.5,
          py: !drawerOpen && level === 1 && !matchDownSM ? 1.25 : 1,
          position: 'relative',
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: alpha(theme.palette.secondary.light, 0.35)
            },
            '&.Mui-selected': {
              bgcolor: theme.palette.secondary.light,
              '&:hover': {
                bgcolor: theme.palette.secondary.light
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: alpha(theme.palette.secondary.light, 0.35)
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: theme.palette.secondary.light
              },
              bgcolor: theme.palette.secondary.light
            }
          })
        }}
      >
        <Box
          display={'flex'}
          flexGrow={1}
          alignItems={'center'}
          onClick={() => {
            if (menu.url) navigate(menu.url);
          }}
        >
          {/* voce di menù raggruppata */}
          {menuIcon && (
            <ListItemIcon
              sx={{
                minWidth: 28,
                color: selected === menu.id ? 'primary.main' : textColor,
                ...(!drawerOpen &&
                  !matchDownSM && {
                    borderRadius: 1.5,
                    width: 36,
                    height: 36,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }),
                ...(!drawerOpen &&
                  selected === menu.id && {
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                    }
                  })
              }}
            >
              {menuIcon}
            </ListItemIcon>
          )}
          {(drawerOpen || (!drawerOpen && level !== 1) || matchDownSM) && (
            <ListItemText
              primary={<Typography sx={{ color: theme.palette.primary.main, ml: 3 }}>{menu.title}</Typography>}
              secondary={
                menu.caption && (
                  <Typography variant="caption" color="secondary">
                    {menu.caption}
                  </Typography>
                )
              }
            />
          )}
        </Box>
        {/* freccia chevron per determinare apertura / chiusura della voce di menù */}
        {(drawerOpen || (!drawerOpen && level !== 1)) &&
          (openMini || open ? (
            <IconButton onClick={() => setOpen(false)}>
              <UpOutlined style={{ fontSize: '0.625rem', marginLeft: 1, color: theme.palette.primary.lighter }} />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpen(true)}>
              <DownOutlined style={{ fontSize: '0.625rem', marginLeft: 1 }} />
            </IconButton>
          ))}
        {/* Popup quando il drawer è minimizzato e mostra solo l'icona */}
        {!drawerOpen && !matchDownSM && (
          <PopperStyled
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            style={{
              zIndex: 2001
            }}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, 1]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{navCollapse}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>
      {(drawerOpen || matchDownSM) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{navCollapse}</List>
        </Collapse>
      )}
    </>
  );
};

export default NavCollapse;
