import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}
// header del menù sinistro
const DrawerHeader = ({ open }: Props) => {
  return <DrawerHeaderStyled open={open} />;
};

export default DrawerHeader;
