// material-ui

// project import
import Profile from './Profile';
import Notification from 'components/custom/navigationBar/NotificationButton';
import Task from 'components/custom/navigationBar/TaskButton';
import Note from 'components/custom/navigationBar/NoteButton';
import HeaderBreadcrumb from 'components/custom/HeaderBreadcrumb';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { setNotesCount } from 'store/reducers/helpers';
import { useLocation, useParams } from 'react-router';
import { useEndpoint } from 'hooks/useEndpoint';
import { CountedData } from 'types/counted-data';
import { NoteDTO } from 'types/dto/note.dto';
import { resourceSelector } from 'utils/filtersUtils/resourceSelector';
import { formatBackOrder } from 'utils/formattation';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { pathname } = useLocation();
  const { orderId, buildingId, id } = useParams();

  const [subjectToSend, setSubjectToSend] = useState<string[]>([]);
  const [sectionFilter, setSectionFilter] = useState<string | null>(resourceSelector({ location: pathname }));

  // l'header è sempre montato, quindi facevo la get delle note e dei task qui

  const getNotes = useEndpoint<CountedData<NoteDTO>, 'get'>({
    method: 'get',
    endpoint: '/notes',
    queryKey: 'get-notes',
    queryParams: {
      filterBy: JSON.stringify(
        [
          sectionFilter ? { field: 'resourceSlug', value: { equals: sectionFilter } } : undefined,
          subjectToSend.length ? { field: 'subjects', value: { equals: subjectToSend } } : undefined
        ].filter((r) => r)
      )
    },
    options: {
      onSuccess: ({ data }) => {
        dispatch(setNotesCount(data.filteredCount));
      }
    }
  });

  /*
   *  * nota *
   *          i task, e le note devono essere filtrare in base al percorso in cui ci si trova
   *          per esempio se si è in delle rotte relative agli ordini, le note e i task devono
   *          mostrare un badge con il numero di task/note relative alla risorsa ordini (a cui sei assegnato)
   *
   *          allo stesso tempo deve essere possibile, mentre si è nella pagina "ordini", e quindi
   *          c'è un filtro iniziale impostato sulla risorsa ordini, di impostare un'altra risorsa
   *          e quindi vedere magari quali task ci sono sulle strutture
   *
   *          in più è possibile filtrare per soggetto, per soggetto si intende una entità specifica
   *          esempi  {risorsa: strutture, soggetto: struttura tal dei tali (id)}
   *                  {risorsa: utenti, soggetto: super mario (id)}
   */

  // se cambia il path devo rifare il filtro secondo la risorsa
  useEffect(() => {
    setSectionFilter(resourceSelector({ location: pathname }));
  }, [pathname]);

  useEffect(() => {
    // se cambio path, filtro di risorsa o soggetto
    // devo rifare la get delle note
    getNotes.refetch();
  }, [pathname, sectionFilter, subjectToSend]);

  useEffect(() => {
    /*
     * se sono in una pagina specifica di un'entità, es. (:buildingName/:buildingId)
     * devo settare il filtro del soggetto secondo l'id che ho a disposizione
     */
    orderId
      ? setSubjectToSend([orderId])
      : buildingId
        ? setSubjectToSend([formatBackOrder(buildingId)])
        : id
          ? setSubjectToSend([formatBackOrder(id)])
          : setSubjectToSend([]);
  }, [orderId, buildingId, id]);

  /*
   * * nota *
   *        tutto questo si potrebbe fare senza questi useEffect? Certo.
   */
  return (
    <>
      <Box display="flex" flexGrow={1}>
        {/* componente per mostrare genericamente il tipo di sezione in cui ci si trova
         * più navigazione su sezioni superiori
         */}
        <HeaderBreadcrumb />
      </Box>

      {/* pulsante di visualizzazione numero dei task della sezione, e gestione apertura/chiusura */}
      <Task />
      {/* pulsante di visualizzazione numero delle note della sezione, e gestione apertura/chiusura */}
      <Note />
      {/* pulsante del profilo personale */}
      <Profile />
      {/* pulsante di visualizzazione numero delle notifiche della sezione, e gestione apertura/chiusura */}
      <Notification />
    </>
  );
};

export default HeaderContent;
