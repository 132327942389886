import { lazy } from 'react';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'components/Loadable';
import CommonLayout from 'layout/CommonLayout';

// il componente è importato con lazy loading, perchè se si è un utente abituale
// inutile montare rotte che probabilmente vedrai davvero poche volte
const AuthLogin = Loadable(lazy(() => import('pages/auth')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        // GuestGuard è la guardia delle rotte pubbliche, l'app deve essere inizializzata
        // se inizializzata si controlla che l'utente abbia già dei token
        // se positivo l'app prova a buttarlo direttamente dentro (validazione token + refresh)
        // altrimenti continua sulle rotte pubbliche (login)
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      // esistono solo 2 rotte e un componente: il login,
      // i super gestiscono le password, e gli utenti stessi possono cambiarle solo dall'interno
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: 'login',
          element: <AuthLogin />
        }
        // {
        //   path: 'forgot-password',
        //   element: <AuthForgotPassword />
        // }
      ]
    }
  ]
};

export default LoginRoutes;
