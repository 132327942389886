import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import dayjs from 'dayjs';
import packageJson from '../../../../../../package.json';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  // selezione, per ogni voce di menù, della tipologia corretta
  // in realtà gestito dentro NavGroup
  // se non è gestito, ritorna l'errore del default
  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        pt: 1,
        '& > ul:first-of-type': { mt: 0 },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box>{navGroups}</Box>
      {/* footer custom con versione dell'app, data e ora di aggiornamento */}
      {drawerOpen && (
        <Box p={2} sx={{ whiteSpace: 'normal', width: '100%' }}>
          <Typography component="p" variant="caption" color="textSecondary">
            Versione:{' '}
            <Typography color="primary" component="span" variant="caption">
              {packageJson.version}
            </Typography>
            <br />
            Aggiornamento:{' '}
            <Typography color="primary" component="span" variant="caption">
              {dayjs(process.env.REACT_APP_LAST_UPDATE).format('DD/MM/YYYY | HH:mm')}
            </Typography>
            <br />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Navigation;
