import { Box, Pagination, Typography, useTheme } from '@mui/material';
import { Header } from './Header';
import { ActionsAndFilters } from './ActionsAndFilters';
import { useEffect, useState } from 'react';
import { PaginationType } from 'types/custom/pagination';
import { CountedData } from 'types/counted-data';
import { useEndpoint } from 'hooks/useEndpoint';
import { NotificationType } from 'types/dto/notification.dto';
import { useSelector } from 'store';
import { SingleNotification } from './SingleNotification';
import { NotificationsLoading } from 'components/custom/loading/NotificationsLoading';
import NoElement from 'assets/images/illustrations/noElement';
import { formatSorting } from 'utils/filtersUtils/formatSorting';
import { useWindowLayout } from 'hooks/useWindowHeight';
import useLoader from 'hooks/useLoader';

interface Props {
  closeDrawer: () => void;
}
let availableNotificationsHeight = 0;
export const Notifications = ({ closeDrawer }: Props) => {
  const { windowHeight } = useWindowLayout();
  const responsiveNotificationsPageSize = Math.floor(availableNotificationsHeight / 52);
  const theme = useTheme();
  const { isLoading } = useLoader();

  const { user } = useSelector((state) => state.auth);
  const [pagination, setPagination] = useState<PaginationType>({ pageIndex: 0, pageSize: 0 });
  const [searchFilter, setSearchFilter] = useState<string>();
  const [filter, setFilter] = useState<{ field: string | string[]; value: { equals: null } | { not?: number } }[] | undefined>(undefined);
  const descOrder = formatSorting([{ id: 'id', desc: true }]);

  const getNotifications = useEndpoint<CountedData<NotificationType>, 'get'>({
    method: 'get',
    endpoint: '/notifications',
    queryKey: 'notifications',
    queryParams: {
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
      filterBy: filter
        ? JSON.stringify(filter.concat([{ field: ['user', 'id'], value: { not: user?.id } }]))
        : JSON.stringify([{ field: ['user', 'id'], value: { not: user?.id } }]),
      orderBy: descOrder,
      search: searchFilter ?? undefined
    }
  });

  const notifications = getNotifications.data?.data;

  useEffect(() => {
    if (windowHeight) {
      availableNotificationsHeight = windowHeight - 260;
      setPagination({ pageIndex: 0, pageSize: responsiveNotificationsPageSize });
    }
  }, [windowHeight, responsiveNotificationsPageSize]);

  return (
    <Box className="right-drawer-el-container">
      <Header onClose={closeDrawer} setFilter={setSearchFilter} />
      <Box className="standard-margin-container flex dir-col">
        <ActionsAndFilters setFilter={setFilter} />
        {notifications && notifications.data.length ? (
          <Box className="flex dir-col just-btwn" flexGrow={1}>
            <Box className="notification-container vertical-standard-margin">
              {isLoading || getNotifications.isFetching ? (
                <NotificationsLoading pageSize={pagination.pageSize} />
              ) : (
                notifications.data.map((notification, i) => {
                  return <SingleNotification key={`notification-${i}`} notification={notification} />;
                })
              )}
            </Box>
            <Pagination
              onChange={(e, v) => setPagination({ pageIndex: v - 1, pageSize: responsiveNotificationsPageSize })}
              count={notifications.filteredCount && Math.ceil(notifications.filteredCount / responsiveNotificationsPageSize)}
              size="large"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                pb: 2,
                '.MuiPaginationItem-root': { position: 'relative', zIndex: 1 }
              }}
            />
          </Box>
        ) : notifications && !notifications.data.length ? (
          <Box
            className="flex dir-col ali-center vertical-standard-margin"
            padding={6}
            sx={{ backgroundColor: `${theme.palette.action.hover}` }}
          >
            <NoElement width={300} />
            <Typography variant="h6" sx={{ mt: 4 }}>
              Nessuna notifica
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
