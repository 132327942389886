import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PermissionsGuard from 'utils/route-guard/PermissionsGuard';
import { WebsocketProvider } from 'components/custom/Websocket';

// manutenzione
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));

// Analytics
const Analytics = Loadable(lazy(() => import('pages/analytics')));

//Autopilot
const Autopilot = Loadable(lazy(() => import('pages/autopilot')));
const NewTarget = Loadable(lazy(() => import('pages/autopilot/NewTarget')));
const NewFlux = Loadable(lazy(() => import('pages/autopilot/NewFlux')));
const DeleteNode = Loadable(lazy(() => import('pages/autopilot/DeleteNode')));
const CreateEvent = Loadable(lazy(() => import('pages/autopilot/CreateEvent')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// Documents
const AllDocuments = Loadable(lazy(() => import('pages/documents')));

// Collaborators
const Collaborators = Loadable(lazy(() => import('pages/collaborators')));
const NewCollaborator = Loadable(lazy(() => import('pages/collaborators/new-collaborator')));
const CollaboratorAccount = Loadable(lazy(() => import('pages/collaborators/collaborator')));

// Buildings
const Buildings = Loadable(lazy(() => import('pages/buildings')));
const Building = Loadable(lazy(() => import('pages/buildings/building')));

// Orders
const Orders = Loadable(lazy(() => import('pages/orders')));
const Order = Loadable(lazy(() => import('pages/orders/order')));

// Users (clienti)
const FastUsers = Loadable(lazy(() => import('pages/users/fast')));
const SwitchoUsers = Loadable(lazy(() => import('pages/users/switcho')));
const Users = Loadable(lazy(() => import('pages/users/platform')));
const UserAccount = Loadable(lazy(() => import('pages/users/platform/user')));

// Referral codes
const ReferralCodes = Loadable(lazy(() => import('pages/referral-codes')));

// Faqs
const Faqs = Loadable(lazy(() => import('pages/faqs')));

// Partners
const Partners = Loadable(lazy(() => import('pages/partners')));

// Impostazioni
const Settings = Loadable(lazy(() => import('pages/settings')));

//Log
const Logs = Loadable(lazy(() => import('pages/log')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <WebsocketProvider>
          {/* guardia di autorizzazione per le rotte private */}
          <AuthGuard>
            {/* guardia dei permessi necessari per poter vedere componenti / usare endpoint */}
            <PermissionsGuard>
              {/* layout utilizzato per tutte le rotte protette */}
              <MainLayout />
            </PermissionsGuard>
          </AuthGuard>
        </WebsocketProvider>
      ),
      children: [
        // path in cui ho fatto delle prove con sun editor, un wyswig estendibile open source (...e free)
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'analytics',
          element: <Analytics />
        },
        {
          // tutti gli utenti che utilizzano il backoffice, è escluso dalla lista il super (tu)
          path: 'collaborators',
          element: <Collaborators />,
          children: [
            {
              path: 'new',
              element: <NewCollaborator />
            },
            // il super e chi ha i permessi può vedere le rotte ':id/:fullname'
            // chi magari ha permessi ridotti può solo vedere il 'me'
            {
              path: ':id/:fullname',
              element: <CollaboratorAccount />
            },
            {
              path: 'me',
              element: <CollaboratorAccount />
            }
          ]
        },
        {
          // tutti i clienti dell'app
          path: 'users',
          element: <Users />,
          children: [
            {
              path: ':id/:fullname',
              element: <UserAccount />
            }
          ]
        },
        {
          // non so se si userà davvero, è solo una tabella con degli utenti
          // che dovrebbero arrivare da dei funnel dell'agenzia di marketing
          // per testare nuove funzionalità (???)
          path: 'switcho-users',
          element: <SwitchoUsers />
        },
        {
          // utenti che derivano dai funnel dell'agenzia di marketing
          // hai poco e niente di questi utenti, praticamente una email
          path: 'fast-registration-users',
          element: <FastUsers />
        },
        {
          // gli ordini (contrattazioni) avviate dai clienti
          path: 'orders',
          element: <Orders />,
          children: [
            {
              // il singolo ordine della struttura
              path: ':buildingId/:buildingName/:orderId',
              element: <Order />
            }
          ]
        },
        {
          // le strutture registrate all'app
          path: 'buildings',
          element: <Buildings />,
          children: [
            {
              // la singola struttura
              path: ':buildingId/:buildingName',
              element: <Building />
            }
          ]
        },
        // i referral code che si potranno dare agli utenti
        // o usare in via programmatica attraverso l'autopilot
        // ancora da discutere sull'implementazione lato business
        { path: 'referral-codes', element: <ReferralCodes /> },

        // questa sezione gestisce le FAQ che si vedono sull'app cliente
        { path: 'faq', element: <Faqs /> },

        // impostazioni generali
        { path: 'settings', element: <Settings /> },

        // sezione per gestire i partner di credito di BuyPlus e le loro configurazioni
        { path: 'partners', element: <Partners /> },

        // tutti i documenti richiesti nell'app, con loro settings (quando/dove/come chiederli)
        { path: 'platform-contracts', element: <AllDocuments /> },

        // log di tutte le azioni svolte in backoffice
        { path: 'log', element: <Logs /> },
        {
          // autopilot.
          // un sistema di automatizzazione che dovrebbe poter gestire tutto nel backoffice & app
          // dalle azioni da svolgere dopo un ordine, alla creazione dei task, o il loro cambio stato
          // gli utenti di alto livello del backoffice devono poter impostare degli eventi -> azioni
          // avendo a disposizioni tutti i dati di qualsiasi risorsa all'interno del nostro database
          // poter fare dei confronti (in base al tipo del dato), e quindi poter impostare dei filtri
          // sulle entità del database (utenti | strutture | ordini | tasks)
          // esempio minimo -> strutture della provincia di brindisi con un rating di 7 o più

          // la gestione è stata fatta con una struttura a nodi
          // per cui è possibile configurare un nodo e inserirlo a piacimento prima o dopo un altro
          // il frontend è limitato per scelta, ma la struttura backend sotto è altamente flessibile
          // potresti collegare qualsiasi nodo a qualsiasi altro

          path: 'autopilot',
          element: <Autopilot />,
          children: [
            // è la creazione del flusso
            // il flusso è un susseguirsi di nodi che vengono eseguiti alla fine dell'esecuzione del precedente
            // esiste un nodo timer che "blocca" l'esecuzione fino al momento in cui la condizione del nodo stesso
            // non è soddisfatta
            { path: 'create-flux', element: <NewFlux /> },
            { path: ':fluxID', element: <NewFlux /> },
            // il target è un nodo filtro, si sceglie a chi applicare un determinato flusso
            // il componente NewTarget è riutilizzabile, la differenza è nel fatto che il flusso è già stato creato o meno
            { path: 'create-target-preset', element: <NewTarget /> },
            { path: 'target/:targetID', element: <NewTarget /> },
            // creazione del nodo evento, gli eventi sono i creatori di esecuzione di flussi
            // sono loro che danno l'input per attivare un flusso
            // e possono essere di tipo:
            // evento scatenato dal cliente (può essere il click di un bottone, o l'invio di un ordine)
            // evento temporizzato, c'è magari una programmazione da seguire, stile mailing list
            { path: 'create-event', element: <CreateEvent /> },
            // eliminazione di un nodo
            { path: 'delete-node', element: <DeleteNode /> }
          ]
        },
        {
          // rotte ereditate, servono per mettere in manutenzione l'app
          path: '/maintenance',
          element: <CommonLayout />,
          children: [
            {
              path: '404',
              element: <MaintenanceError />
            },
            {
              path: '500',
              element: <MaintenanceError500 />
            },
            {
              path: 'under-construction',
              element: <MaintenanceUnderConstruction />
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
