import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'contexts/AuthContext';
import { Box } from '@mui/material';
import Offline from 'components/custom/Offline';
import Idle from 'components/custom/Idle';
import useConfig from 'hooks/useConfig';
import { useEffect, useMemo } from 'react';
import { AxiosInstanceProvider } from 'contexts/AxiosInstanceContext';
import { ModalProvider } from 'contexts/ModalContext';
import { useDispatch } from 'store';
import { activeItem } from 'store/reducers/menu';
import { SnackbarProvider } from 'notistack';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ScreenShouldBeDesktop } from 'components/ScreenShouldBeDesktop';
import Snackbar from 'components/custom/Snackbar';
require('dayjs/locale/it');
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
export const queryClient = new QueryClient();

const App = () => {
  const dispatch = useDispatch();
  const pathname = useMemo(() => window.location.pathname, []);
  console.log('rendering app');
  const { onChangeFontFamily, onChangeLocalization, i18n } = useConfig();

  useEffect(() => {
    // setta le impostazioni dopo il primo render
    // sicuramente migliorabile come concetto
    dayjs.locale(i18n);
    onChangeFontFamily("'Work Sans', sans-serif");
    onChangeLocalization(i18n);
    const locations = pathname.split('/');
    let activeLocation = locations[0];
    if (activeLocation !== 'dashboard') {
      if (activeLocation === 'users') {
        dispatch(activeItem({ openItem: ['users'] }));
      } else {
        dispatch(activeItem({ openItem: [activeLocation] }));
      }
    }
  }, []);

  return (
    // provider di MUI per il tema e gli override dei componenti
    <ThemeCustomization>
      {/* 
          provider dei Locales, utile per settare l'internazionalizzazione
          al momento assente
      */}
      <Locales>
        {/*
            provider del sistema di autenticazione interno a Buyplus
        */}
        <AuthProvider>
          {/*
              provider dell'istanza Axios, in modo da usare una singola istanza
              tramite cui fare tutte le chiamate al nostro server
          */}
          <AxiosInstanceProvider>
            {/*
                provider di react-query, le chiamate vengono cachate se non specificato altrimenti,
                ogni componente fa accesso a questa cache tramite il nostro fetch (useEndpoint),
                chiamate già eseguite accedono a questa cache se non specificato altrimenti
                es. refetch()
            */}
            <QueryClientProvider client={queryClient}>
              {/*
                  provider di tutti i modal presenti nell'app
              */}
              <ModalProvider>
                {/*
                    provider di tutti gli snackbar presenti nell'app, deriva da notistack
                */}
                <SnackbarProvider maxSnack={5}>
                  {/*
                      provider di localizzazione, serve sopratutto ai componenti che usano:
                      @mui/x-date-pickers 
                  */}
                  <LocalizationProvider adapterLocale={'it'} dateAdapter={AdapterDayjs}>
                    {/*
                        componente di fallback se il dispositivo non è in formato desktop *ereditato
                    */}
                    <ScreenShouldBeDesktop />
                    <Box sx={{ overflow: { xs: 'hidden', md: 'visible' } }}>
                      {/*
                          tutte le rotte presenti nell'app
                      */}
                      <Routes />
                      {/*
                          fallback se il dispositivo è offline *ereditato
                      */}
                      <Offline />
                      {/*
                          fallback se il dispositivo è in pausa *ereditato
                      */}
                      <Idle />
                    </Box>
                    {/*
                        effettivo componente snackbar
                    */}
                    <Snackbar />
                  </LocalizationProvider>
                </SnackbarProvider>
              </ModalProvider>
            </QueryClientProvider>
          </AxiosInstanceProvider>
        </AuthProvider>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
