// material-ui
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// project import
import HeaderContent from './HeaderContent';

// types
import { CustomToolbar } from './CustomToolbar';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //
// componente ereditato, parzialmente modificato
interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'transparent';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'transparent';

  return (
    <CustomToolbar>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        sx={{
          color: 'text.primary',
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
          '&:hover': { bgcolor: 'secondary.light' }
        }}
      >
        {!open ? <MenuIcon /> : <MenuOpenIcon />}
      </IconButton>
      <HeaderContent />
    </CustomToolbar>
  );
};

export default Header;
