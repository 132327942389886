import { Box, IconButton, TextareaAutosize, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomTooltip } from 'components/custom/CustomTooltip';

interface Props {
  values: {
    message: string;
    resource?: string | null;
    subject?: string[] | undefined;
  };
  enabled: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}
export const CreateNote = ({ values, handleChange, handleSubmit, enabled }: Props) => {
  const theme = useTheme();
  let shiftPressed = false;
  return (
    <Box className="flex just-btwn ali-center" width="98%">
      <CustomTooltip content="Le note possono essere inserite in riferimenti specifici">
        <Box width="100%" className="flex ali-center just-btwn">
          <TextareaAutosize
            className="create-note-input"
            placeholder="Crea una nuova nota"
            name="message"
            id="message"
            maxRows={10}
            disabled={!enabled}
            value={values.message}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.code === 'ShiftRight' || e.code === 'ShiftLeft') {
                shiftPressed = true;
              }
              if (e.code === 'Enter' && !shiftPressed) {
                handleSubmit();
              }
            }}
          />
          <IconButton
            sx={values.message ? { px: '10px', width: '2%' } : { px: '10px', width: '2%', color: theme.palette.action.selected }}
            aria-label="toggle note visibility"
            onClick={() => handleSubmit()}
            edge="end"
            color="inherit"
          >
            <AddIcon />
          </IconButton>
        </Box>
      </CustomTooltip>
    </Box>
  );
};
