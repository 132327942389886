import { Avatar, Box, TextareaAutosize, Typography } from '@mui/material';
import { NoteDTO } from 'types/dto/note.dto';
import { dispatch, useSelector } from 'store';
import { KeyboardEvent, useState } from 'react';
import { useEndpoint } from 'hooks/useEndpoint';
import { openSnackbar } from 'store/reducers/snackbar';
import useModal from 'hooks/useModal';
import { CustomTooltip } from 'components/custom/CustomTooltip';
import { avatarInitials, humanizeDate } from 'utils/formattation';
import { AskConfirmation } from 'components/custom/AskConfirmation';
import { useFormik } from 'formik';
import { queryClient } from 'App';

interface Props {
  notes: NoteDTO[];
}
const NoteList = ({ notes }: Props) => {
  const { user } = useSelector((state) => state.auth);
  const { openModal } = useModal();
  const [noteToDelete, setNoteToDelete] = useState<number>();
  const editingNotesForm = useFormik<{ data: (NoteDTO & { isEditing: boolean })[] }>({
    enableReinitialize: true,
    initialValues: { data: notes.map((n) => ({ ...n, isEditing: false })) },
    onSubmit: () => {
      handlePatchNote();
    }
  });
  const selectedEditNote = editingNotesForm.values.data.find((n) => n.isEditing);

  const handlePatchNote = () => {
    const editedNote = { ...selectedEditNote };
    delete editedNote.isEditing;
    patchNote.mutate(editedNote);
    editingNotesForm.setValues(editingNotesForm.initialValues);
  };
  const patchNote = useEndpoint<Partial<NoteDTO>, 'patch'>({
    method: 'patch',
    endpoint: `/notes/${selectedEditNote?.id}`,
    mutationKey: `edit-note-${selectedEditNote?.id}`,
    options: {
      onSuccess: () => {
        queryClient.refetchQueries('get-all-notes');
        dispatch(
          openSnackbar({
            message: `Nota modificata con successo.`,
            open: true,
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `patch-note`
          })
        );
      }
    }
  });

  const deleteNote = useEndpoint<undefined, 'delete'>({
    method: 'delete',
    endpoint: `/notes/${noteToDelete}`,
    mutationKey: `delete-note-${noteToDelete}`,
    options: {
      onSuccess: () => {
        queryClient.refetchQueries('get-all-notes');
        setNoteToDelete(undefined);
        dispatch(
          openSnackbar({
            message: `Nota eliminata con successo.`,
            open: true,
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `delete-note`
          })
        );
      }
    }
  });

  const handleDeleteNote = () => {
    deleteNote.mutate(undefined);
  };

  const handleEditNote = async (i: number) => {
    editingNotesForm.setValues(editingNotesForm.initialValues);
    editingNotesForm.setFieldValue(`data[${i}].isEditing`, true);
  };

  const handleUndoEditNote = () => {
    editingNotesForm.setValues(editingNotesForm.initialValues);
  };

  const notePressEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      handlePatchNote();
    }
  };

  return (
    <Box>
      {editingNotesForm.values.data.map((note, i) => (
        <Box key={note.id} className="flex ali-center vertical-standard-margin" sx={{ width: '99%', ml: 1, mb: 1 }}>
          <CustomTooltip content={`${note.user.firstname} ${note.user.lastname}`}>
            <Avatar sx={{ mr: 2 }}>{avatarInitials(`${note.user.firstname} ${note.user.lastname}`)}</Avatar>
          </CustomTooltip>
          <Box className="single-note">
            <Box display="flex">
              <Typography variant="caption" sx={{ mr: 0.5 }}>
                Riferimento:
              </Typography>
              <Typography variant="caption" sx={{ mr: 0.5, fontWeight: 700 }}>
                {note.label ?? 'Generale'},
              </Typography>
              <Typography variant="caption" sx={{ mr: 0.5 }}>
                Collaboratore:
              </Typography>
              <Typography variant="caption" sx={{ mr: 0.5, fontWeight: 700 }}>
                {note.user.firstname} {note.user.lastname}
              </Typography>
            </Box>
            <Typography className="date" variant="caption" position="absolute" right={8} top={8}>
              {note.lastEdit ? humanizeDate(note.lastEdit) : 'Non definito'}
            </Typography>

            {user?.id === note.user.id ? (
              note.isEditing ? (
                <>
                  <Typography className="save clickable" variant="caption" onClick={() => editingNotesForm.handleSubmit()}>
                    Salva
                  </Typography>
                  <Typography className="separator1" variant="caption">
                    -
                  </Typography>
                  <Typography className="undo clickable" variant="caption" onClick={() => handleUndoEditNote()}>
                    Annulla
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="modify clickable" variant="caption" onClick={() => handleEditNote(i)}>
                    Modifica
                  </Typography>
                  <Typography className="separator0" variant="caption">
                    -
                  </Typography>
                  <Typography
                    className="delete clickable"
                    variant="caption"
                    onClick={() => {
                      setNoteToDelete(note.id);
                      openModal({
                        title: 'Eliminazione nota',
                        content: (
                          <AskConfirmation
                            confirmationText={'Vuoi davvero eliminare questo commento?'}
                            onConfirmCallback={() => handleDeleteNote()}
                          />
                        )
                      });
                    }}
                  >
                    Elimina
                  </Typography>
                </>
              )
            ) : (
              <></>
            )}
            {user?.id === note.user.id && note.isEditing ? (
              <TextareaAutosize
                className="create-note-input"
                value={note.message}
                onChange={(e) => editingNotesForm.setFieldValue(`data[${i}].message`, e.target.value)}
                onKeyDown={(e) => notePressEnter(e)}
              />
            ) : (
              <Typography variant="body2" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
                {note.message}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default NoteList;
