import { Box, ClickAwayListener, Typography } from '@mui/material';
import StatusFilter from './StatusFilter';
import OrderSelect from 'components/custom/OrderSelect/OrderSelect';
import { useEndpoint } from 'hooks/useEndpoint';
import { useState } from 'react';
import { queryClient } from 'App';

interface Props {
  sorting: string;
  setSorting: React.Dispatch<React.SetStateAction<string>>;
  setStateFilter: React.Dispatch<
    React.SetStateAction<
      | {
          field: ['state', 'key'];
          value:
            | {
                equals: 'created' | 'pending' | 'paused' | 'expired' | 'completed' | 'deleted';
              }
            | undefined;
        }
      | undefined
    >
  >;
  showUnread:
    | {
        field: 'lastReadAt';
        value: {
          equals: null;
        };
      }
    | undefined;
  setShowUnread: React.Dispatch<
    React.SetStateAction<
      | {
          field: 'lastReadAt';
          value: {
            equals: null;
          };
        }
      | undefined
    >
  >;
}
export const TasksFilters = ({ sorting, setSorting, setStateFilter, showUnread, setShowUnread }: Props) => {
  const [showStatusMenu, setShowStatusMenu] = useState<boolean>(false);

  const readAllTasks = useEndpoint<undefined, 'put'>({
    method: 'put',
    endpoint: '/tasks/readings/all',
    mutationKey: 'read-all-tasks',
    options: {
      onSuccess: () => {
        queryClient.refetchQueries('get-all-tasks');
        queryClient.refetchQueries('get-tasks-readings');
      }
    }
  });

  const showAll = () => {
    setShowUnread(undefined);
    setSorting('{ "field": "lastEdit", "sort": "desc" }');
  };

  const showOnlyUnread = () => {
    setShowUnread({ field: 'lastReadAt', value: { equals: null } });
    setStateFilter(undefined);
  };

  return (
    <Box mx="auto" className="flex just-btwn ali-center vertical-standard-margin">
      <Box display="flex">
        <Typography
          variant="body2"
          className="textSecondary clickable"
          sx={{ mr: 1 }}
          onClick={() => {
            readAllTasks.mutate(undefined);
          }}
        >
          Segna come letti
        </Typography>
        {showUnread ? (
          <Typography variant="body2" className="textSecondary clickable" sx={{ ml: 2 }} onClick={showAll}>
            Mostra tutti
          </Typography>
        ) : (
          <Typography variant="body2" className="textSecondary clickable" sx={{ ml: 2 }} onClick={showOnlyUnread}>
            Mostra non letti
          </Typography>
        )}
      </Box>
      <Box className="flex ali-center" sx={{ mr: 1, mb: 0.5 }}>
        <ClickAwayListener onClickAway={() => setShowStatusMenu(false)}>
          <Box position="relative">
            <Typography variant="body2" className="textSecondary clickable" sx={{ mt: 0.5, mr: 3 }} onClick={() => setShowStatusMenu(true)}>
              Status
            </Typography>

            <StatusFilter statusOpen={showStatusMenu} setStatusOpen={setShowStatusMenu} setStateFilter={setStateFilter} />
          </Box>
        </ClickAwayListener>
        <Box>
          <OrderSelect sorting={sorting} setSorting={setSorting} filters={['created', 'expire', 'update']} />
        </Box>
      </Box>
    </Box>
  );
};
